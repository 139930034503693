@charset "UTF-8";

@use './settings/variable' as v;
@use './settings/mixin' as r;
@use './settings/_path' as p;

.nk23_c-header {
	@include r.reset;
}

#wrapper .nk23_c-header {
	background-color: #fff;
	position: relative;
	z-index: 9;
	overflow: inherit;
	$root: &;

	.nk23_c-btn01 {
		@include r.btn01(v.$color19, v.$color19, #fff, v.$color0);
	}

	// .nk23_c-header__menuchild .
	&__menuchild {
		padding-left: 8px;

		// .nk23_c-header__menuchild__text .
		&__text {
			padding-left: 10px;
			text-indent: -10px;
			color: #fff;
			font-size: 14px;
			line-height: 20px;
			pointer-events: none;

			@include r.hover {
				text-decoration: underline;
				opacity: 1;
			}

			img {
				&.icon-plus {
					margin-left: 8px;
					display: inline-block;
					width: 20px;
					position: relative;
					top: -1px;

					@include r.sp {
						margin-left: 4px;
					}
				}
			}
		}

		&__item {
			// .nk23_c-header__menuchild__item:not(:last-child) .
			&:not(:last-child) {
				margin-bottom: 8px;
			}

			&.is-small {
				.nk23_c-header__menuchild__text {
					font-size: 12px;

					@include r.hover {
						text-decoration: none;
					}

					img {
						&.icon-plus {
							width: 16px;
							margin-right: 8px;
							display: inline-block;
							margin-left: 0;

							@include r.sp {
								margin-right: 4px;
								margin-left: 0;
							}
						}
					}
				}
			}
		}
	}

	// .nk23_c-header__menu .
	&__menu {
		padding: 0 16px;

		@include r.sp {
			padding: 0 18px !important;
			position: relative;
		}

		&::-webkit-scrollbar {
			@include r.sp {
				display: none;
			}
		}

		// .nk23_c-header__menu__inner .
		&__inner {
			display: flex;

			@include r.pc {
				width: 100%;
				justify-content: center;
			}
		}

		// .nk23_c-header__menu__text .
		&__text {
			padding: 8px 24px 8px;
			border-radius: 8px 8px 0 0;
			cursor: pointer;
			transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
			font-size: 14px;
			line-height: 1;
			display: flex;
			align-items: center;
			width: max-content;
			height: 100%;
			color: rgba($color: v.$color0, $alpha: 0.75);

			@include r.sp {
				padding: 8px 16px;
			}

			// .nk23_c-header__menu__text img .
			img {
				margin-right: 8px;
				width: 20px;
				opacity: 0.75;

				@include r.sp {
					margin-right: 4px;
					width: 18px;
				}

				&.is-active {
					display: none;
				}

				&.icon-plus {
					margin-left: 8px;
					width: 20px;

					@include r.sp {
						width: 18px;
						margin-left: 4px;
					}
				}
			}
		}

		&__item {
			width: max-content !important;

			@include r.sp {
				height: auto !important;
			}

			// #wrapper .nk23_c-header__menu__item.is-active .
			&.is-active {
				&:not(:first-child) {
					.nk23_c-header__menu__text {
						@include r.sp {
							margin-left: 18px;
						}
					}
				}
			}

			&:before {
				display: none;
			}

			&.has-menuchild {
				&:after {
					@include r.pc {
						content: '';
					}
				}
			}

			// .nk23_c-header__menu__item:after .
			&:after {
				bottom: -18px;
				opacity: 0;
				transition: 0.3s ease-in-out;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				pointer-events: none;
				z-index: 2;
				width: 16px;
				height: 11px;
				background: url(p.$path + 'images/common/icon/icon_menusub.svg')
					no-repeat center / 16px auto;
			}

			@include r.hover {
				&:after {
					opacity: 1;
				}

				.nk23_c-header {
					&__menu__text {
						background-color: rgba($color: v.$color0, $alpha: 0.1);
						font-weight: 400;
						opacity: 1;
						color: v.$color0;

						img {
							opacity: 1;
						}
					}

					&__child {
						visibility: visible;
						opacity: 1;
						pointer-events: all;
					}

					&__menuchild__text {
						pointer-events: all;
					}
				}
			}

			&.is-active {
				.nk23_c-header__menu__text {
					background-color: v.$color0;
					font-weight: 700;
					color: #fff;

					img {
						display: none;
						opacity: 1;

						&.is-active {
							display: unset;
						}

						&.icon-plus {
							display: inline-block !important;
						}
					}
				}
			}
		}
	}

	// #wrapper .nk23_c-header__child .
	&__child {
		padding-top: 16px;
		top: 100%;
		position: absolute;
		width: max-content;
		transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
		visibility: hidden;
		pointer-events: none;
		opacity: 0;
		max-width: 100vw;

		@include r.pc {
			color: #fff;
			min-width: 313px;
		}

		// .nk23_c-header__child__inner .
		&__inner {
			background-color: v.$color0;

			@include r.pc {
				padding: 16px 40px;
				border-radius: 8px;
				display: flex;
				border: 1px solid #fff;
			}

			@include r.sp {
				background-color: #fff;
			}
		}

		&__col {
			padding: 16px 0;
			min-width: 211px;

			// .nk23_c-header__child__col:not(:last-child) .
			&:not(:last-child) {
				@include r.pc {
					padding-right: 16px;
					border-right: 2px solid rgba($color: v.$color19, $alpha: 0.25);
				}
			}
			&:last-child {
				.nk23_c-header__menuchild__item {
					&:last-child {
						&.is-active {
							border-bottom: 1px solid v.$color19;
						}
					}
				}
			}

			&:only-child {
				@include r.pc {
					padding-right: 0;
				}
			}

			&:nth-child(2) {
				@include r.pc {
					padding-right: 40px;
					padding-left: 40px;
				}
			}

			&:last-child {
				@include r.pc {
					padding-right: 0;
				}
			}

			&:last-child:not(:only-child) {
				@include r.pc {
					padding-left: 40px;
				}
			}
		}

		// .nk23_c-header__child__title .
		&__title {
			margin-bottom: 16px;
			font-size: 16px;
			line-height: 1.5;
			font-weight: 700;
		}

		&.is-style1 {
			@include r.pc {
				left: 50% !important;
				transform: translateX(-50%);
			}

			.nk23_c-header__child {
				&__inner {
					@include r.pc {
						width: 597px;
					}
				}
			}
		}

		&.is-first {
			@include r.pc {
				left: -137% !important;
			}

			@media (min-width: 768px) and (max-width: 1264px) {
				left: -122% !important;
			}

			.nk23_c-header__child {
				&__inner {
					@include r.pc {
						width: 642px;
					}
				}

				&__col {
					@include r.pc {
						width: 50%;
					}
				}
			}
		}

		&.is-last {
			@include r.pc {
				left: 31% !important;
				transform: translateX(-50%);
			}
		}

		&.is-center {
			@include r.pc {
				left: 50% !important;
				transform: translateX(-50%);
			}
		}

		&.is-style2 {
			@include r.pc {
				left: -13px !important;
			}
		}
	}

	&__submenu {
		padding: 0 16px;

		@include r.sp {
			padding: 0 4px;
		}

		// .nk23_c-header__submenu__inner .
		&__inner {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -16px;

			@include r.pc {
				justify-content: center;
			}

			@include r.sp {
				padding-bottom: 8px;
				margin: 0 -4px;
			}

			&.is-style1 {
				@include r.max-w(600px) {
					justify-content: center;
				}

				.nk23_c-header__submenu {
					&__item {
						@include r.max-w(600px) {
							width: max-content;
						}

						@include r.max-w(499px) {
							width: 33.3333%;
						}
					}
				}
			}

			&.is-style2 {
				@include r.sp {
					justify-content: center;
				}

				.nk23_c-header__submenu {
					&__item {
						@include r.sp {
							width: max-content;
							min-width: unset;
							padding: 0 8px;
						}

						@include r.max-w(499px) {
							width: 33.3333%;
						}
					}
				}
			}
		}

		// .nk23_c-header__submenu__text .
		&__text {
			font-size: 12px;
			line-height: 1;
			opacity: 0.7;
			width: max-content;
			position: relative;
			transition: 0.3s ease-in-out;

			@include r.pc {
				display: flex;
				align-items: center;
				min-height: 29px;
			}

			@include r.sp {
				padding: 16px 0 4px;
			}

			@include r.w374 {
				font-size: 10px;
			}

			@include r.hover {
				opacity: 0.4;
			}

			&:after {
				content: '';
				width: 100%;
				height: 1px;
				background-color: v.$color0;
				position: absolute;
				bottom: -1px;
				left: 50%;
				transform: translateX(-50%);
				opacity: 0;
				transition: 0.3s ease-in-out;
			}

			img {
				margin-left: 4px;
				width: 16px;

				&.icon-plus {
					@include r.sp {
						display: inline-block;
					}
				}
			}
		}

		// .nk23_c-header__submenu__item .
		&__item {
			padding: 0 16px;
			width: max-content;

			@include r.sp {
				padding: 0 4px;
				width: 14%;
				text-align: center;
				min-width: 93.5px;
			}

			@include r.max-w(559px) {
				width: 25%;
			}

			@include r.w374 {
				min-width: 80px;
			}

			&.is-active {
				.nk23_c-header__submenu__text {
					font-weight: 700;
					opacity: 1;

					&:after {
						opacity: 1;
					}

					@include r.hover {
						opacity: 1;

						&:after {
							opacity: 1;
						}
					}
				}
			}
		}
	}

	&__middle {
		border-bottom: 1px solid v.$color0;
		position: relative;
		z-index: 1;

		&--SP {
			@include r.sp {
				display: none;
			}
		}
	}

	// .nk23_c-header__bottom .
	&__bottom {
		border-bottom: 1px solid v.$color19;

		@include r.sp {
			// padding-bottom: 8px;
		}
	}

	&__btns {
		display: flex;
		align-items: center;

		@include r.sp {
			padding-left: 8px;
		}

		.nk23_c-header__btn {
			&:not(:first-child) {
				margin-left: 8px;
			}
		}
	}

	// .nk23_c-header__btnmenu .
	&__btnmenu {
		@include r.sp {
			padding-bottom: 4px;
			margin-left: 8px;
			width: 44px;
			height: 42px;
			background: #fff url(p.$path + 'images/common/icon/icon_menu.svg')
				no-repeat center top 6px / 18px auto;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			@include r.shadow(v.$color19);
			@include r.border(v.$color19);
			border-radius: 8px;
			font-size: 10px;
			line-height: 1;
			font-weight: 700;
		}
	}

	// .nk23_c-header__btn .
	&__btn {
		border-radius: 8px;
		@include r.shadow(v.$color24);
		@include r.border(v.$color24);
		background-color: v.$color25;
		width: 156px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		line-height: 14px;
		font-weight: 700;
		color: #fff;

		@include r.not-a;
		transition: none;

		@include r.sp {
			flex-direction: column;
			width: 56px;
			height: 42px;
			font-size: 8px;
			line-height: 1;
		}

		@include r.hover {
			box-shadow: none !important;
			opacity: 1;
			position: relative;
			top: 2px;
		}

		&.is-style1 {
			width: 104px;
			@include r.shadow(v.$color19);
			@include r.border(v.$color19);
			background-color: #fff;
			color: v.$color0;

			img {
				max-width: 20px;
				width: 100%;
			}
		}

		&.is-style2 {
			color: v.$color0;
			background-color: #fff;
			@include r.shadow(v.$color26);
			@include r.border(v.$color26);

			// .nk23_c-header__btn.is-style2 img .
			img {
				@include r.sp {
					margin-bottom: 5px;
				}
			}
		}

		&.is-color1 {
			@include r.shadow(v.$color27);
			@include r.border(v.$color27);
			background-color: v.$color26;

			img {
				@include r.sp {
					margin-bottom: 4px;
				}
			}
		}

		// .nk23_c-header__btn__text .
		&__text {
			text-align: center;
		}

		img {
			max-width: 60px;
			width: 100%;

			@include r.pc {
				margin-right: 4px;
			}

			@include r.sp {
				margin-bottom: 2px;
				max-width: 40px;
			}
		}
	}

	// .nk23_c-header__top .
	&__top {
		padding: 8px 16px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		position: relative;
		z-index: 1;
		@include r.shadow1;

		@include r.sp {
			padding: 8px 8px 8px 16px;
		}

		&__right {
			flex: 1;
			display: flex;
			justify-content: flex-end;
		}
	}

	// .nk23_c-header__form .
	&__form {
		position: relative;

		@include r.pc {
			border: 1px solid v.$color19;
			margin-right: 16px;
			border-radius: 999px;
		}

		@include r.sp {
			margin-bottom: 8px;
			border-radius: 4px;
			background-color: #fff;
		}

		// .nk23_c-header__form__inner .
		&__inner {
			@include r.pc {
				display: flex;
				align-items: center;
			}

			@include r.sp {
				padding: 8px;
			}
		}

		// .nk23_c-header__form__input .
		&__input {
			padding: 0 48px 0 16px;
			display: flex;
			align-items: center;
			flex: 1;

			@include r.sp {
				padding: 0 40px 0 8px;
			}

			input {
				font-size: 12px;
				line-height: 18px;
			}
		}

		// .nk23_c-header__form__btn .
		&__btn {
			width: 16px;
			height: 16px;
			position: absolute;
			right: 16px;
			z-index: 1;
			cursor: pointer;

			@include r.pc {
				top: 50%;
				transform: translateY(-50%);
			}

			@include r.sp {
				bottom: 9px;
				right: 15px;
				width: 20px;
				height: 20px;
			}

			@include r.hover {
				&:after {
					opacity: 1;
				}
			}

			&:after {
				@include r.pc {
					content: '';
					width: 28px;
					height: 28px;
					position: absolute;
					top: 50%;
					left: 50%;
					border-radius: 50%;
					transform: translate(-50%, -50%);
					background-color: v.$base-color;
					display: inline-block;
					z-index: -1;
					opacity: 0;
					transition: 0.3s ease-in-out;
				}
			}

			img {
				display: block;
				width: 100%;
			}
		}
	}

	// .nk23_c-header__list .
	&__list {
		display: flex;
		position: relative;

		* {
			z-index: 2;
		}

		@include r.pc {
			padding: 4px 8px 4px 4px;
			border-right: 1px solid v.$color19;
			min-width: 253px;
		}

		input[type='radio'] {
			// display: none;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			display: block;
			pointer-events: none;
		}

		.nk23_c-header__tab {
			height: 30px;
			font-size: 1.2rem;
			border-radius: 999px;
			cursor: pointer;
			transition: color 0.15s ease-in, background-color 0.15s linear;

			@include r.hover {
				background: v.$color40;
			}

			@include r.pc {
				min-width: 60px;
				line-height: 2.9rem;
				display: block;
				text-align: center;
			}

			@include r.sp {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 25px;
				width: 70px;
				position: relative;
			}

			@include r.w374 {
				width: 57px;
			}
		}

		.nk23_c-header__glider {
			position: absolute;
			display: flex;
			height: 30px;
			width: 60px;
			background-color: v.$color0;
			z-index: 1;
			border-radius: 999px;
			transition: all 0.25s ease-out;

			@include r.sp {
				left: 4px;
				height: 25px;
				width: 70px;
			}

			@include r.w374 {
				width: 57px;
			}
		}

		input[type='radio'] {
			&:checked {
				& + label {
					color: #ffffff;
					font-weight: 700;

					@include r.hover {
						background: none;
					}
				}
			}
		}

		input[id='radio-1'],
		input[id='radio-sp-1'] {
			&:checked {
				& ~ .nk23_c-header__glider {
					transform: translateX(0);
				}
			}
		}

		input[id='radio-2'],
		input[id='radio-sp-2'] {
			&:checked {
				& ~ .nk23_c-header__glider {
					transform: translateX(100%);
				}
			}
		}

		input[id='radio-3'],
		input[id='radio-sp-3'] {
			&:checked {
				& ~ .nk23_c-header__glider {
					transform: translateX(200%);
				}
			}
		}

		input[id='radio-4'],
		input[id='radio-sp-4'] {
			&:checked {
				& ~ .nk23_c-header__glider {
					transform: translateX(300%);
				}
			}
		}

		@include r.sp {
			padding-left: 4px;
			padding-right: 4px;
			padding-bottom: 6px;
			margin-bottom: 9px;
			border-bottom: 1px solid v.$color19;
		}

		// .nk23_c-header__list:after .
		&:after {
			right: -5px;
			width: 6px;
			height: 12px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			background: url(p.$path + 'images/common/icon/icon_right-header.svg')
				no-repeat center / 6px auto;

			@include r.pc {
				content: '';
			}
		}

		// .nk23_c-header__list__text .
		&__text {
			padding: 6px 8px;
			border-radius: 999px;
			display: block;

			@include r.pc {
				cursor: pointer;
				transition: 0.3s ease-in-out;
			}

			@include r.sp {
				padding: 2px 14px 3px;
			}

			@include r.w374 {
				padding: 2px 8px 3px;
			}

			@include r.hover {
				opacity: 1;
				background-color: v.$color40;
			}
		}

		// .nk23_c-header__list__item .
		&__item {
			font-size: 12px;
			line-height: 1.5;

			@include r.w374 {
				font-size: 10px;
			}

			&.is-active {
				font-weight: 700;
				color: #fff;
				margin-right: 8px;

				@include r.sp {
					margin-right: 4px;
				}

				// .nk23_c-header__list__item.is-active .nk23_c-header__list__text .
				.nk23_c-header__list__text {
					background-color: v.$color0;
				}
			}
		}
	}

	// .nk23_c-header__logo .
	&__logo {
		display: flex;
		max-width: 168px;
		margin-right: auto;

		@include r.pc {
			padding-top: 3px;
		}

		@include r.sp {
			max-width: 134px;
		}

		&__link {
			display: flex;
		}

		img {
			width: 100%;
		}
	}

	// .nk23_c-header__content .
	&__content {
		@include r.sp {
			position: fixed;
			top: 0;
			width: 100%;
			height: 100%;
			left: 0;
			background: url(p.$path + 'images/common/icon/icon_menu-x.svg') no-repeat
				top 14px right 15px / 20px auto;
			transition: 0.2s ease-in-out;
			z-index: 9;
			visibility: hidden;
		}

		&.is-open {
			@include r.sp {
				visibility: visible;
				background-color: rgba($color: v.$color0, $alpha: 0.75);
			}

			a {
				@include r.sp {
					pointer-events: all;
				}
			}

			.nk23_c-header__content__inner {
				@include r.sp {
					transform: translateX(0);
				}
			}
		}

		// .nk23_c-header__content__inner .
		&__inner {
			@include r.sp {
				transform: translateX(-100%);
				padding: 8px 8px 0 8px;
				height: 100%;
				background-color: v.$color28;
				display: flex;
				width: calc(100% - 50px);
				margin-right: auto;
				flex-direction: column;
				transition: 0.2s ease-in-out;
			}
		}

		a {
			@include r.sp {
				pointer-events: none;
			}
		}

		.nk23_c-header {
			// .nk23_c-header__content .nk23_c-header__menu .
			&__menu {
				@include r.sp {
					padding: 0 !important;
					overflow-x: hidden;
					overflow-y: auto;
					flex: 1;
					// height: calc(100% - 135px);
				}

				// .nk23_c-header__content .nk23_c-header__menu__text .
				&__text {
					@include r.sp {
						padding: 8px 32px 8px 16px;
						width: 100%;
						background-color: transparent;
						border-radius: 8px;
						color: v.$color0;
						font-size: 16px;
						line-height: 1.5;
						font-weight: 700;
						position: relative;
						word-break: break-word;
					}

					@include r.w374 {
						padding-left: 8px;
						font-size: 14px;
					}

					&:before,
					&:after {
						@include r.sp {
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							background-color: v.$color0;
							transition: 0.3s ease-in-out;
							border-radius: 2px;
						}
					}

					// .nk23_c-header__content .nk23_c-header__menu__text:before .
					&:before {
						@include r.sp {
							right: 14px;
							width: 14px;
							height: 2px;
						}
					}

					// .nk23_c-header__content .nk23_c-header__menu__text:after .
					&:after {
						@include r.sp {
							right: 20px;
							width: 2px;
							height: 14px;
						}
					}

					&.is-active {
						// .nk23_c-header__content .nk23_c-header__menu__text.is-active:after .
						&:after {
							@include r.sp {
								top: 35%;
								transform: rotate(90deg);
								opacity: 0;
							}
						}
					}

					// .nk23_c-header__content .nk23_c-header__menu__text img .
					img {
						@include r.sp {
							margin-right: 8px;
							width: 24px;
							opacity: 1;
						}

						// .nk23_c-header__menu__text img.icon-plus .
						&.icon-plus {
							margin-right: 0;

							@include r.sp {
								width: 18px;
							}
						}
					}
				}

				// .nk23_c-header__content .nk23_c-header__menu__item .
				&__item {
					@include r.sp {
						border-radius: 8px;
						width: 100% !important;
						background: #fff;
						overflow: hidden;
					}

					&:not(:last-child) {
						@include r.sp {
							margin-bottom: 8px;
						}
					}

					// .nk23_c-header__content .nk23_c-header__menu__item.is-home .
					&.is-home {
						@include r.sp {
							background: #fff
								url(p.$path + 'images/common/icon/icon_right.svg') no-repeat
								center right 14px / 9px auto;
						}

						.nk23_c-header__menu__text img {
							@include r.sp {
								margin-right: 4px;
							}
						}
					}

					&.is-active {
						.nk23_c-header__menu__text {
							@include r.sp {
								color: v.$color0;
								width: 100%;
								padding: 8px 8px 8px;
								background-color: transparent;
								border-radius: 4px;
							}
						}
					}

					&.has-menuchild {
						.nk23_c-header__menu__text {
							&:before,
							&:after {
								@include r.sp {
									content: '';
								}
							}
						}
					}
				}

				// .nk23_c-header__content .nk23_c-header__menu__inner .
				&__inner {
					@include r.sp {
						padding-bottom: 40px;
						height: max-content;
						display: block;
					}
				}
			}

			// .nk23_c-header__content .nk23_c-header__btns .
			&__btns {
				@include r.sp {
					margin-bottom: 10px;
					padding-left: 0;
				}
			}

			&__btn {
				@include r.sp {
					width: 50%;
					max-width: 100px;
					height: 50px;
				}

				// .nk23_c-header__content .nk23_c-header__btn__text .
				&__text {
					@include r.sp {
						font-size: 10px;
					}

					@include r.w374 {
						font-size: 8px;
					}
				}

				// .nk23_c-header__content .nk23_c-header__btn img .
				img {
					@include r.sp {
						max-width: 70px;
					}

					@include r.w374 {
						max-width: 60px;
					}
				}

				&.is-style1 {
					@include r.sp {
						width: 50%;
						max-width: 67px;
					}

					img {
						@include r.sp {
							max-width: 20px;
						}
					}
				}

				&:not(:last-child) {
					@include r.sp {
						margin-right: 4px;
					}
				}

				&:not(:first-child) {
					@include r.sp {
						margin-left: 0;
					}
				}
			}

			// .nk23_c-header__content .nk23_c-header__child .
			&__child {
				@include r.sp {
					padding: 0;
					padding-left: 15px;
					border-top: 1px solid v.$color19;
					display: none;
					transition: none;
					width: 100%;
					max-width: 100%;
					position: unset;
					top: auto;
					bottom: auto;
					left: auto;
					right: auto;
					visibility: visible;
					opacity: 1;
					pointer-events: all;
				}

				@include r.w374 {
					padding-left: 8px;
				}

				// .nk23_c-header__content .nk23_c-header__child__title .
				&__title {
					@include r.sp {
						padding: 10px 35px 13px 9px;
						margin-bottom: 0;
						font-size: 16px;
						line-height: 1.5;
						font-weight: 400;
						position: relative;
					}

					@include r.w374 {
						padding-left: 8px;
						font-size: 14px;
					}

					&:before,
					&:after {
						@include r.sp {
							content: '';
							position: absolute;
							background-color: v.$color0;
							transition: 0.3s ease-in-out;
							border-radius: 2px;
						}
					}

					// .nk23_c-header__content .nk23_c-header__child__title:before .
					&:before {
						@include r.sp {
							top: 22px;
							right: 14px;
							width: 14px;
							height: 1px;
						}
					}

					// .nk23_c-header__content .nk23_c-header__child__title:after .
					&:after {
						@include r.sp {
							right: 20px;
							top: 15px;
							width: 1px;
							height: 14px;
						}
					}

					&.is-active {
						&:after {
							@include r.sp {
								transform: rotate(90deg);
								opacity: 0;
							}
						}
					}
				}

				&__col {
					@include r.sp {
						padding: 0;
					}

					&:not(:last-child) {
						@include r.sp {
							border-bottom: 1px solid v.$color19;
						}
					}
				}
			}

			// .nk23_c-header__content .nk23_c-header__menuchild .
			&__menuchild {
				@include r.sp {
					padding-left: 16px;
					display: none;
					border-top: 1px solid v.$color19;
				}

				@include r.w374 {
					padding-left: 8px;
				}

				// .nk23_c-header__content .nk23_c-header__menuchild__item .
				&__item {
					@include r.sp {
						display: flex;
						width: 100%;
						background: #fff
							url(p.$path + 'images/common/icon/icon_right-small.svg') no-repeat
							center right 18px / 5px auto;
					}

					&.is-active {
						@include r.sp {
							background-color: v.$color28;
						}
					}

					&:not(:last-child) {
						@include r.sp {
							margin-bottom: 0;
							border-bottom: 1px solid v.$color19;
						}
					}

					&.is-style1 {
						@include r.sp {
							background-image: none;
							justify-content: flex-end;
						}

						.nk23_c-header__menuchild__text {
							@include r.sp {
								width: max-content;
								padding-left: 0;
								padding-right: 16px;
							}

							img {
								&.icon-plus {
									display: inline-block;

									@include r.sp {
										margin-right: 4px;
										margin-left: 0;
									}
								}
							}
						}
					}
				}

				// .nk23_c-header__content .nk23_c-header__menuchild__text .
				&__text {
					@include r.sp {
						padding: 8px 32px 8px 8px;
						width: 100%;
						font-size: 14px;
						line-height: 20px;
						color: v.$color0;
						text-indent: 0;
						position: relative;
					}

					@include r.w374 {
						font-size: 12px;
						line-height: 1.5;
					}

					img {
						// .nk23_c-header__content .nk23_c-header__menuchild__text img.icon-plus .
						&.icon-plus {
							display: inline-block;

							@include r.sp {
								margin-left: 4px;
								width: 18px;
							}
						}
					}
				}

				&.no-accor {
					@include r.sp {
						border-top: none;
						display: block;
					}
				}
			}
		}

		.nk23_c-btn01 {
			@include r.sp {
				margin-bottom: 10px;
				width: 100%;
				font-size: 12px;
				font-weight: 700;
				border-radius: 8px;
			}

			img {
				@include r.sp {
					width: 16px;
					margin-right: 4px;
				}
			}
		}
	}

	// .nk23_c-header__close .
	&__close {
		@include r.sp {
			width: 50px;
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			z-index: 1;
		}
	}

	&__tab {
		&.is-active {
			@include r.sp {
				color: #fff;
				position: relative;
				z-index: 3;
			}
		}
	}

	&.is-login {
		.nk23_c-header {
			&__content {
				.nk23_c-header {
					&__btn {
						@include r.sp {
							max-width: 117px;
						}

						&.is-style1 {
							@include r.sp {
								max-width: 67px;
							}
						}
					}
				}
			}
		}
	}

	@include r.swiper;

	.swiper {
		&-button {
			&-prev,
			&-next {
				@include r.sp {
					top: 50%;
				}

				&:focus {
					outline: none;
				}
			}

			&-disabled {
				opacity: 0;
			}
		}
	}

	// &.is-loading {
	// 	.nk23_c-header__menu__item {
	// 		overflow: hidden;
	// 	}
	// }
}

.page-top {
	.nk23_c-header {
		&__bottom {
			display: none;
		}
	}
}
